/* scrollbar */
@import '~simplebar-react/dist/simplebar.min.css';

/* carousel */
@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';

/* lightbox */
@import '~yet-another-react-lightbox/styles.css';
@import '~yet-another-react-lightbox/plugins/captions.css';
@import '~yet-another-react-lightbox/plugins/thumbnails.css';

/* lazy image */
@import '~react-lazy-load-image-component/src/effects/blur.css';

.heroBannerVideo {
  height: 100vh;
}

.MuiDateRangeCalendar-root > div:first-child {
  display: none !important;
}